$color-primary: #ff124f;
$color-secondary: #14f1ff;
$color-blue-secondary: #00B3EF;
$color-grey: #e5e5e5;
$color-grey-2: #979797;
$color-grey-3: #333333;
$color-grey-4: #191919;

.bg-grey {
  background-color: $color-grey;
}
.bg-grey-2 {
  background-color: $color-grey-2;
}
.bg-grey-3 {
  background-color: $color-grey-3;
}
.bg-grey-4 {
  background-color: $color-grey-4;
}
.bg-blue-1 {
  background-color: $color-blue-secondary
}