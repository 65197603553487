.input-range__track {
  background: white !important;
}

.input-range__track .input-range__track--active {
  background: #00E024 !important;
}

.input-range__slider {
  appearance: none;
  background: #00E024 !important;
  border: 3px solid white !important;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 22px !important;
  width: 22px !important;
  margin-left: -0.5rem !important;
  margin-top: -0.85rem !important;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.input-range__label {
  display: none !important;
}