@import '../colors/index.scss';

.btn-warning {
  background-color: $color-primary;
  color: white;
}

.btn-primary {
  background-color: white;
  color: black;
}

.btn-secondary {
  background-color: black;
  color: white;
}

.btn-tertiary {
  background-color: #191919;
  color: white;
}

.btn-info {
  background-color: $color-blue-secondary;
  color: white;
}
