.modal-overlay {
  background-color: rgba(52, 51, 51, 0.831);
  position: fixed;
  inset: 0px;
  z-index: 20;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-button {
  top: 275px;
}
