@tailwind base;
@tailwind components;
@tailwind utilities;

@import './colors/index.scss';
@import './header/index.scss';
@import './buttons/index.scss';
@import './text/index.scss';
@import './modal/index.scss';
@import './slideout/slide.scss';
@import './chat/index.scss';
@import './accordian/index.scss';
@import './range-slider/index.scss';

@font-face {
  font-family: SF_Compact;
  src: url('../assets/Fonts/SFCompactDisplay-Black.ttf'),
    url('../assets/Fonts/SFCompactDisplay-Bold.ttf'),
    url('../assets/Fonts/SFCompactText-BoldItalic.ttf'),
    url('../assets/Fonts/SFCompactText-Heavy.ttf'),
    url('../assets/Fonts/SFCompactText-LightItalic.ttf'),
    url('../assets/Fonts/SFCompactText-MediumItalic.ttf'),
    url('../assets/Fonts/SFCompactText-Regular.ttf'),
    url('../assets/Fonts/SFCompactText-RegularItalic.ttf');
}

@font-face {
  font-family: SF_Pro;
  src: url('../assets/Fonts/SF-Pro-Medium.otf'),
    url('../assets/Fonts/SF-Pro-Bold.otf'),
    url('../assets/Fonts/SF-Pro-Regular.otf');
}

html,
body {
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

.text-primary {
  color: $color-primary;
}

@media (min-width: 1537px) {
  .container {
    max-width: 100%;
  }
}

.loader {
  border: 6px solid #f3f3f300;
  border-radius: 50%;
  border-top: 6px solid #00e024;
  border-right: 6px solid #00e024;
  border-bottom: 6px solid #00e024;
  width: 70px;
  height: 70px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-screen {
  height: 85vh;
}

body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.clips-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../assets//Icons/arrrow-down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

body {
  overflow-x: hidden;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.text-left {
  text-align: left !important;
}

.justify-start {
  justify-content: start;
}





.fade-in {
  animation: fade-in 1.5s
}
.fade-in-fast {
  opacity: 0;
  animation: fade-in-fast 1s forwards;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fade-in-fast {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fast {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(-0);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar-track {
  background: #111;
}
*::-webkit-scrollbar-thumb {
  background-color:  #444 ;
}
.short-scroll::-webkit-scrollbar-track {
  background: black;
}
.short-scroll::-webkit-scrollbar-thumb {
  background-color: black ;
}
.short-scroll:hover::-webkit-scrollbar-track {
  background: #111;
}
.short-scroll:hover::-webkit-scrollbar-thumb {
  background-color:  #444 ;
}
.short-scroll::-webkit-scrollbar {
  height: 8px;
}