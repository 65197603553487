@import '../colors/index.scss';

.text-warning {
  color: $color-primary;
}

.text-secondary {
  color: $color-secondary;
}

.text-grey-2 {
  color: $color-grey-2;
}
