.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 0;
}

.scrollable-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  flex-grow: 1;
  overflow: auto;
  /* for Firefox */
  min-height: 0;
}

.scrollable-content::-webkit-scrollbar {
  display: none;
}
