.menu {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  transition: 0.4s;
  z-index: 9999;
  transform: translateX(-100%);
}

.menu.active {
  transform: translateX(0);
}

.menu-right {
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  transition: 0.4s;
  z-index: 9999;
  transform: translateX(100%);
}

.menu-right.active {
  transform: translateX(0);
}